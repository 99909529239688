import HomePage from "./01-main-demo";
import BackToTop from "./backToTop";

export default function Home() {
  // console.log(process.env.NEXT_PUBLIC_DB_HOST,"from env")
  return (
    <>
      <main className="">
        <HomePage />
        <BackToTop />
      </main>
    </>
  );
}
